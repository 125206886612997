.navbar-item img {
  max-height: 3rem;
}

@media screen and (min-width: 769px) {

  .custionario-sintomas .field .field-label {
    flex-grow: 5;
  }

  .field:not(:last-child) {
    margin-bottom: 1rem;
  }
  
}

.indicaciones-generales ul {
  margin-left: 30px;
}
.indicaciones-generales ul li {
  list-style: circle;
}